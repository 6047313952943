import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Over mij" />
      <h1>Over mij</h1>
      <div className="home-div">
        <StaticImage
          src="../images/Lauren.png"
          alt="Lauren Maenen"
          className="image"
          imgStyle={{ objectFit: "contain" }}
          placeholder="none"
          loading="eager"
        />
        <p>
          In juli 2021 behaalde ik mijn diploma ‘Master in de Klinische
          Psychologie’ aan de Vrije Universiteit Brussel. <br />
          <br />
          Daarna ben ik gestart als zelfstandig psycholoog binnen de groepspraktijken: Dandelion (Meeuwen), Huisartsenpraktijk (Loksbergen) en Let’s Grow (Aarschot).<br />
          <br />
          Sinds kort werk ik ook bij de huisartsenpraktijk Kwadrant in Rotem (Dilsen-Stokkem). Hier werk ik
als ELP wat betekent dat je aan een verlaagd tarief in begeleiding kan komen (enkel op
doorverwijzing via de huisarts).
          <br />
          <br/>
          Sinds oktober 2021 ben ik gestart met de bijkomende opleiding ‘Oplossingsgerichte therapie’ aan
het Korzybski Instituut.
          <br />
          <br />
          Ik ben officieel erkend als psycholoog door de Psychologencommissie.{" "}
          <br />
        </p>
      </div>
    </Layout>
  );
};

export default IndexPage;
